import React from 'react';
import css from './style.module.scss';
import cn from 'classnames';
import spinner from '../assets/spinner.svg'
export default function Button({ className, handler, valid, isLoading, children }) {
  
  
  return (
    <div className={css.container}>
      <button
        disabled={valid ? false : true}
        className={cn(css.btn, className)}
        onClick={handler}
        style={{cursor: valid ? 'pointer' : 'not-allowed'}}
      >
       {isLoading && <img src={spinner} alt="spinner" />}
       {children}
      </button>
    </div>
  );
}
