export const Arrow = (color = '#3675FE') => (
    <svg
  width="19"
  height="14"
  viewBox="0 0 19 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2 7.0999L7.02693 12.1268C7.12196 12.2219 7.27649 12.2203 7.36964 12.1235L17.2 1.8999"
    stroke={color}
    strokeWidth="3.2"
    strokeLinecap="round"
  />
</svg>
)
