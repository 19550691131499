import {  useState } from 'react';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import Instruction from './components/Instruction';
import Main from './components/Main';
import Partners from './components/Partners';
import Plans from './components/Plans';
import Services from './components/Services';

function App() {
  const [offsetMain, setOffsetMain] = useState()
  const [offsetServices, setOffsetServices] = useState()
  const [offsetContact, setOffsetContact] = useState()
  const [offsetInstruction, setOffsetInstruction] = useState()
  const [offsetModel, setOffsetModel] = useState()

  const [langValue, setLangValue] = useState('en');

  const allScrollHeight = {
    model: offsetModel,
    services: offsetServices,
    contact: offsetContact + 300,
    instruction: offsetInstruction
  };

  // console.log(allScrollHeight)
  return (
    <div className="app">
      <Header allScrollHeight={allScrollHeight} setLangValue={setLangValue}  langValue={langValue}/>
      <Main getOffsetHeight={(type) => setOffsetMain(type)} langValue={langValue} />
      <Services  getOffsetHeight={(type) => setOffsetServices(type)} />
      <Instruction getOffsetHeight={(type) => setOffsetInstruction(type)} />
      <Plans scrollTo={allScrollHeight.contact} getOffsetHeight={(type) => setOffsetModel(type)} />
      <Partners />
      <Contact getOffsetHeight={(type) => setOffsetContact(type)}  />
      <Footer />
    </div>
  );
}

export default App;
