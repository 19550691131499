import React from 'react';
import css from '../styles/footer.module.scss';
export default function Footer() {
  return (
    <footer>
      <div className={css.wrapper}>
        <div className={css.title}>© 2016-2023 TRUCKDOC.</div>
        <div className={css.textBLock}>
          <div className={css.text}>privacy policy</div>
          <div className={css.text}>terms & conditions</div>
        </div>
      </div>
    </footer>
  );
}
