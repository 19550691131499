import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow } from '../assets/arrow';
import css from '../styles/plans.module.scss';
import Button from '../ui/Button';

import oneIcon from '../assets/one.svg';
import twoIcon from '../assets/two.svg';
import threeIcon from '../assets/three.svg';

export default function Plans({ scrollTo, getOffsetHeight }) {
  const { t } = useTranslation();
  const ref = useRef();
  const onScroll = useCallback(() => {
    window.scrollTo({
      top: scrollTo + 300,
      behavior: 'smooth',
    });
  }, [scrollTo]);
  useEffect(() => {
    getOffsetHeight(ref.current.offsetTop);
  }, [ref]);
  const plans = [
    {
      status: t('block.business-model.cloud-start.header'),
      text: t('block.business-model.cloud-start.details'),
      cost: 0,
      goals: [
        t('block.business-model.cloud-start.option-1'),
        t('block.business-model.cloud-start.option-2'),
      ],
      img: oneIcon,
    },
    {
      status: t('block.business-model.pro-mode.header'),
      text: t('block.business-model.pro-mode.details'),
      cost: 500,
      goals: [
        t('block.business-model.pro-mode.option-1'),
        t('block.business-model.pro-mode.option-2'),
      ],
      img: twoIcon,
    },
    {
      status: t('block.business-model.custom.header'),
      text: t('block.business-model.custom.details'),
      cost: 700,
      goals: [
        t('block.business-model.custom.option-1'),
        t('block.business-model.custom.option-2'),
      ],
      img: threeIcon,
    },
  ];
  return (
    <div className={css.plansSection} ref={ref}>
      <div className={css.wrapper}>
        <div className={css.title}>{t('menu.busness-model')}</div>
        <div className={css.plans}>
          {plans.map((el, index) => (
            <div className={css.blockPlan} key={index}>
              <div className={css.icons}>
                <img src={el.img} alt="icon" />
              </div>
              <div className={css.status}>{el.status}</div>
              <div className={css.text}>{el.text}</div>
              {/* <div className={css.cost}>€ {el.cost}</div> */}
              <div className={css.goals}>
                {el.goals.map((el, index) => (
                  <div className={css.goal} key={el}>
                    {index === 1 ? Arrow() : Arrow('#4882FE')}
                    <div style={{ width: '80%' }}> {el}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <Button handler={onScroll} valid className={css.marginBtn} >
        {t('block.contacts.header')}
        </Button>
      </div>
    </div>
  );
}
