import React, { useEffect, useRef } from 'react';
import css from '../styles/services.module.scss';
import planetIcon from '../assets/planet.svg';
import picture from '../assets/picture.svg';
import shield from '../assets/shield-outline.svg';
import table from '../assets/table.svg';
import geo from '../assets/geo.svg';
import clock from '../assets/clock.svg';
import file from '../assets/file-text.svg';
import mark from '../assets/mark.svg';
import monitor from '../assets/monitor.svg';
import { useTranslation } from 'react-i18next';
import { articleIcon } from '../assets/article';

export default function Services({ getOffsetHeight }) {
  const ref = useRef();
  const { t } = useTranslation();

  const blocks = [
    {
      img: table,
      text: t('block.features.task_builder'),
      openText: t('block.features.task_builder.details'),
    },
    {
      img: planetIcon,
      text: t('block.features.translator'),
      openText: t('block.features.translator.details'),
    },
    {
      img: geo,
      text: t('block.features.truck-navigator'),
      openText: t('block.features.truck-navigator.details'),
    },
    {
      img: picture,
      text: t('block.features.server-driven-ui'),
      openText: t('block.features.server-driven-ui.details'),
    },
    {
      img: shield,
      text: t('block.features.encrypted-messaging'),
      openText: t('block.features.encrypted-messaging.details'),
    },
    {
      img: file,
      text: t('block.features.doc-scanner'),
      openText: t('block.features.doc-scanner.details'),
    },
    {
      img: clock,
      text: t('block.features.support'),
      openText: t('block.features.support.details'),
    },
    {
      img: mark,
      text: t('block.features.monitoring'),
      openText: t('block.features.monitoring.details'),
    },
    {
      img: monitor,
      text: t('block.features.api'),
      openText: t('block.features.api.details'),
    },
  ];

  useEffect(() => {
    getOffsetHeight(ref.current.offsetTop);
  }, [ref]);

  return (
    <div className={css.services} ref={ref}>
      <div className={css.wrapper}>
        <div className={css.left}>
          <div className={css.title}>{t('block.features.header')}</div>
          <div className={css.text}>{t('block.features.article-first')}</div>
          <div className={css.text}>{t('block.features.article-sec')}</div>
        </div>
        <div className={css.right}>
          {blocks.map((el, index) => (
            <div className={css.block} key={index}>
              <div className={css.container}>
                <img src={el.img} alt="icon" />
              </div>
              <div className={css.text}>
                {el.text}
                {articleIcon()}
                <div className={css.openText}>{el.openText}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
