import React, { useEffect, useRef } from 'react';

import css from '../styles/instruction.module.scss';
import cn from 'classnames';

import first from '../assets/newfirst.png';
import second from '../assets/newSecond.png';
import third from '../assets/newthird.png';
import four from '../assets/newFour.png';
import { useTranslation } from 'react-i18next';

export default function Instruction({ getOffsetHeight }) {
  const { t } = useTranslation();
  const ref = useRef();

  const blocks = [
    {
      number: '01',
      title: t('block.how-app-works.header'),
      desc: t('block.how-app-works.block-1.text'),
      img: first,
      style: css.first,
      styleImg: css.firstImg,
      order: 1,
    },
    {
      number: '02',
      title: t('block.how-app-works.header'),
      desc: t('block.how-app-works.block-2.text'),
      img: second,
      style: css.second,
      styleImg: css.secondImg,
      order: 2,
    },
    {
      number: '03',
      title: t('block.how-app-works.header'),
      desc: t('block.how-app-works.block-3.text'),
      img: third,
      style: css.third,
      styleImg: css.thirdImg,
      order: 1,
    },
    {
      number: '04',
      title: t('block.how-app-works.header'),
      desc: t('block.how-app-works.block-4.text'),
      img: four,
      style: css.four,
      styleImg: css.fourImg,
      order: 2,
    },
  ];

  useEffect(() => {
    getOffsetHeight(ref.current.offsetTop);
  }, [ref]);

  return (
    <div className={css.section} ref={ref}>
      <div className={css.wrapper}>
        <div className={css.title}>{t('block.how-app-works.header')}</div>
        <div className={css.desc}>{t('block.how-app-works.details')}</div>
        <div className={css.blocks}>
          {blocks.map((el, index) => (
            <div className={css.block} key={index}>
              <div className={css.firstCol}>
                <div className={css.number}>{el.number}</div>
                <div className={css.titleBlock}>{el.title}</div>
                <div className={css.text}>{el.desc}</div>
              </div>
              <div className={cn(css.secondCol, el.style)}>
                <div className={cn(css.circle, el.style)}></div>
                <img src={el.img} className={el.styleImg} alt="icon" />
                {index === 0 && <div className={css.newCircle}></div>}
                {index === 1 && (
                  <>
                    <div className={css.circle}></div>
                  </>
                )}
                {index === 3 && <div className={css.circleNew}></div>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
