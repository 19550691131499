import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import css from '../styles/contact.module.scss';

import messageIcon from '../assets/message.svg';
import phone from '../assets/phone.svg';
import Button from '../ui/Button';
import { useTranslation } from 'react-i18next';

export default function Contact({ getOffsetHeight }) {
  const ref = useRef();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [valid, setValid] = useState();
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCode, setResponseCode] = useState('');
  useEffect(() => {
    getOffsetHeight(ref.current.offsetTop);
  }, [ref]);

  function handleSendForm(e) {
    e.preventDefault();
    setIsLoading(true);
    emailjs
      .sendForm(
        'service_pwx9uau',
        'template_jh3vv79',
        e.target,
        '5JToNfdh5GgAUzskY'
      )
      .then((data) => {
        setResponseCode(data.status);
        setIsSend(data.text);
        setIsLoading(false);
      });
    setEmail('');
    setName('');
    setMessage('');
  }

  useEffect(() => {
    if (email && name && message) {
      return setValid(true);
    } else {
      return setValid(false);
    }
  }, [email, name, message]);

  useEffect(() => {
    setTimeout(() => {
      setIsSend('');
      setResponseCode('')
    }, 7000);
  }, [isSend]);

  return (
    <div className={css.section} id="contact" ref={ref}>
      <div className={css.wrapper}>
        <div className={css.contact}>
          <div className={css.first}>
            <div className={css.title}>{t('block.contacts.header')}</div>
            <div className={css.text}>{t('block.contacts.details')}</div>
            <div className={css.info}>
              <div className={css.infoBlock}>
                <div className={css.icon}>
                  <img src={messageIcon} alt="icon" />
                </div>
                <div className={css.desc}>{t('block.contacts.email.link')}</div>
              </div>
              <div className={css.infoBlock}>
                <div className={css.icon}>
                  <img src={phone} alt="icon" />
                </div>
                <a href="tel:+37063218602" className={css.desc}>
                  {t('block.contacrs.phone.link')}
                </a>
              </div>
            </div>
          </div>
          <form className={css.second} onSubmit={handleSendForm}>
            <div className={css.desc}>{t('block.contacts.name')}</div>
            <input
              type="text"
              name="user_name"
              className={css.input}
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
            <div className={css.desc}>{t('block.contacts.email')}</div>
            <input
              type="email"
              name="user_email"
              className={css.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className={css.desc}>{t('block.contacts.message')}</div>
            <textarea
              type="text"
              name="message"
              className={css.input}
              value={message}
              style={{height: '140px', boxSizing: 'border-box', padding: '10px 0'}}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              valid={valid}
              className={css.btnNew}
              isLoading={isLoading}
            >{t('block.contacts.button.text')}</Button>
            {isSend && (

              <div
                className={css.resp}
                style={{ color: responseCode === 200 ? 'green' : 'red' }}
              >
                {responseCode === 200
                  ? t('block.contacts.response.ok')
                  : t('block.contacts.response.error')}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
