import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import css from '../styles/header.module.scss';
import cn from 'classnames';

import logo from '../assets/logo.svg';
import burger from '../assets/burgerMenu.svg';
import cross from '../assets/crossTruck.svg';
import langIcon from '../assets/lang.svg';
import { useTranslation } from 'react-i18next';
import arrow from '../assets/arrow-Top.svg'
import { ClickAwayListener } from '@mui/material';

export default function Header({ allScrollHeight, setLangValue,langValue }) {
  const { t, i18n } = useTranslation();
  const ref = useRef();
  const lang = ['en', 'lt', 'pl'];
  const [active, setActive] = useState(false);
  // const [langValue, setLangValue] = useState('en');
  const [openMenu, setOpenMenu] = useState(false);

  const routes = [
    { title: t('menu.about'), to: allScrollHeight.services },
    { title: t('menu.work-process'), to: allScrollHeight.instruction },
    { title: t('menu.busness-model'), to: allScrollHeight?.model },
    { title: t('menu.contacts'), to: allScrollHeight.contact },
  ];

  const onScroll = useCallback((to) => {
    console.log(to);
    window.scrollTo({
      top: to,
      behavior: 'smooth',
    });
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLangValue(language);
  };

  useEffect(() => {
    if (openMenu) {
      console.log(document.body.style);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [openMenu]);

  return (
    <div className={css.header}>
      <div className={css.wrapper}>
        <div className={css.logo}>
          <img src={logo} alt="logo" />
          <div>TruckDoc</div>
        </div>
        <div className={cn(css.routesContainer, openMenu && css.active)}>
          <div className={css.headerMenu}>
            <div className={css.logo}>
              <img src={logo} alt="logo" />
              <div>TruckDoc</div>
            </div>
            <img
              className={css.cross}
              src={cross}
              alt="cross"
              onClick={() => setOpenMenu(false)}
            />
          </div>
          <div className={css.routes}>
            {routes.map((el, index) => (
              <a
                href={el.id}
                className={css.route}
                key={index}
                onClick={() => {
                  onScroll(el.to);
                  setOpenMenu(false);
                }}
              >
                {el.title}
              </a>
            ))}
            <ClickAwayListener onClickAway={() => setActive(false)}>
              <div
                className={css.drop}
                onClick={() => setActive((prev) => !prev)}
              >
                <div className={css.initialValue}>
                  <img src={langIcon} alt="icon" />
                  <div className={css.text}>{langValue}</div>
                  <img className={cn(css.icon, active && css.active)} src={arrow} alt="arrow" />
                </div>
                {active && (
                  <div className={css.list}>
                    {lang.map((el) => (
                      <div
                        className={cn(
                          css.value,
                          langValue === el && css.active
                        )}
                        key={el}
                        onClick={() => changeLanguage(el)}
                      >
                        {el}
                      </div>
                    ))}
                  </div>
                )}
                
              </div>
            </ClickAwayListener>
          </div>
          <div className={css.footer}>
            <div className={css.text}>privacy policy</div>
            <div className={css.text}>terms & conditions</div>
          </div>
        </div>
        <div
          className={css.burgerMenu}
          onClick={() => setOpenMenu((prev) => !prev)}
        >
          <img src={burger} alt="iconMenu" />
        </div>
      </div>
    </div>
  );
}
