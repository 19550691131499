import React from 'react';

import css from '../styles/partners.module.scss';
import partner1 from '../assets/partner1.svg';
import partner2 from '../assets/partner2.svg';
import partner3 from '../assets/partner3.svg';
import partner4 from '../assets/partner4.svg';
import partner5 from '../assets/partner5.svg';
import partner6 from '../assets/partner6.svg';
import { useTranslation } from 'react-i18next';

const partners = [partner1, partner2, partner3, partner4, partner5, partner6];

export default function Partners() {
  const { t } = useTranslation();
  return (
    <div className={css.section}>
      <div className={css.wrapper}>
        <div className={css.title}>{t('block.partners.header')}</div>
        <div className={css.partners}>
          {partners.map((el, index) => (
            <div className={css.partner} key={index}>
              <img src={el} alt="icon" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
