import React, { useEffect, useRef } from 'react';
import css from '../styles/main.module.scss';
import apple from '../assets/appleMarket.svg';
import google from '../assets/googleMarket.svg';
import formTop from '../assets/formTop.svg';
import iphone from '../assets/iphone.svg';
import ipad from '../assets/ipad.svg';
import arrowTop from '../assets/arrowTop.svg';
import groupTest from '../assets/groupTest.png';
import { useTranslation } from 'react-i18next';
import lines from '../assets/lines.svg';

export default function Main({ getOffsetHeight, langValue }) {
  const { t } = useTranslation();
  const ref = useRef();
  useEffect(() => {
    getOffsetHeight(ref.current.offsetTop);
  }, [ref]);
  return (
    <div className={css.main} ref={ref}>
      <div className={css.wrapper}>
        <div className={css.left}>
          <img src={formTop} alt="forma" className={css.formTop} />
          <div className={css.block}>
            <div
              className={css.title}
              dangerouslySetInnerHTML={{ __html: t('main.header') }}
            />
            {langValue === 'en' && (
              <img className={css.icon} src={lines} alt="lines" />
            )}
          </div>
          <div
            className={css.text}
            dangerouslySetInnerHTML={{ __html: t('main.header.details') }}
          />

          {/* <div className={css.btns}>
          <a href="/" rel="noreferrer" target="_blank">
            <img src={apple} alt="appleIcon" />
          </a>
          <a href="/" rel="noreferrer" target="_blank">
            <img src={google} alt="googleIcon" />
          </a>
        </div> */}
        </div>
        <div className={css.right}>
          <div className={css.figure}></div>
          <div className={css.circle}>
            <img src={groupTest} alt="ipad" />
          </div>
        </div>
      </div>
    </div>
  );
}
